import { useLocation } from 'react-router-dom';
import { MenuIcon } from '@heroicons/react/solid'

const Header = () => {
  const { pathname } = useLocation();

  const linkHoverClasses = 'cursor-pointer hover:underline hover:underline-offset-4 text-gray-500 hover:text-white ease-in-out duration-150';

  return (
    <header className="container px-8 md:px-0 py-8 flex justify-between mb-8 md:mb-20">
      <div className="w-1/2 flex items-centeri">
        <a href="/" className={`font-bold text-2xl md:text-4xl text-white ${linkHoverClasses}`}>parn.co</a>
      </div>

      <div className='flex'>
        <div className="md:hidden">
          <MenuIcon className='w-8 text-gray-500'/>
        </div>
        <div className="font-bold space-x-12 hidden md:flex">
          <a href="/" className={`flex items-center ${linkHoverClasses} ${pathname === '/' && 'text-white underline underline-offset-4'}`}>Home</a>
          <a href="/works" className={`flex items-center ${linkHoverClasses} ${pathname === '/works' && 'text-white underline underline-offset-4'}`}>Works</a>
          {/* <a href="https://linkedin.com/in/parn" rel="noreferrer" target="_blank" className={`flex items-center ${linkHoverClasses} ${pathname === '/works' && 'text-white underline underline-offset-4'}`}>LinkedIn</a>
          <a href="https://instagram.com/parnpresso" rel="noreferrer" target="_blank" className={`flex items-center ${linkHoverClasses} ${pathname === '/works' && 'text-white underline underline-offset-4'}`}>Instagram</a> */}
          {/* <a href="/about" className={`flex items-center ${linkHoverClasses} ${pathname === '/about' && 'text-white underline underline-offset-4'}`}>About</a>
          <a href="/resume" className={`flex items-center ${linkHoverClasses} ${pathname === '/resume' && 'text-white underline underline-offset-4'}`}>Resume</a>
          <a href="/contact" className={`flex items-center ${linkHoverClasses} ${pathname === '/contact' && 'text-white underline underline-offset-4'}`}>Contact</a> */}
        </div>
      </div>
    </header>
  );
}
export default Header;
