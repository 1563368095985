import Header from '../../../components/Header';

const HealthTagPage = () => {
  return (
    <div className='min-h-screen text-white flex flex-col items-center pattern' style={{ backgroundColor: '#111' }}>
      <Header />

    </div>
  );
}
export default HealthTagPage;
