import Header from '../../components/Header';

const WorksPage = () => {
  return (
    <div className='min-h-screen text-white flex flex-col items-center pattern' style={{ backgroundColor: '#111' }}>
      <Header />

      <h1 className='text-4xl font-semibold mb-20'>Projects</h1>

      <div className='container px-8 md:px-0 grid grid-cols-1 md:grid-cols-4 gap-4'>
        {/* <div className='bg-neutral-900 p-4 rounded-xl'>
          <div className='h-60 rounded-lg bg-neutral-300 mb-4'></div>
          <h1 className='text-xl font-semibold px-2 pb-8'>HealthTag</h1>
        </div>
        <div className='bg-neutral-900 p-4 rounded-xl'>
          <div className='h-60 rounded-lg bg-neutral-300 mb-4'></div>
          <h1 className='text-xl font-semibold px-2 pb-8'>HealthTag</h1>
        </div>
        <div className='bg-neutral-900 p-4 rounded-xl'>
          <div className='h-60 rounded-lg bg-neutral-300 mb-4'></div>
          <h1 className='text-xl font-semibold px-2 pb-8'>HealthTag</h1>
        </div>
        <div className='bg-neutral-900 p-4 rounded-xl'>
          <div className='h-60 rounded-lg bg-neutral-300 mb-4'></div>
          <h1 className='text-xl font-semibold px-2 pb-8'>HealthTag</h1>
        </div>
        <div className='bg-neutral-900 p-4 rounded-xl'>
          <div className='h-60 rounded-lg bg-neutral-300 mb-4'></div>
          <h1 className='text-xl font-semibold px-2 pb-8'>HealthTag</h1>
        </div> */}

      </div>
    </div>
  );
}
export default WorksPage;
