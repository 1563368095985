import { FaInstagram, FaLinkedin } from "react-icons/fa";

import Logo from './images/logo.png';
import Header from "../../components/Header";

const HomePage = () => {

  return (
    <div className='min-h-screen text-white flex flex-col items-center pattern' style={{ backgroundColor: '#111' }}>
      <Header />

      <div className="container flex flex-col items-center mb-40">
        <div className='relative flex flex-col items-center mb-8'>
          <img src={Logo} className='w-44 md:w-64' alt='logo' />
          <div className='md:absolute md:top-0 md:left-40 border-8 border-gray-700 rounded-full bg-white text-black font-semibold p-2 w-44 text-center'>
            <h3>👋🏼  &nbsp;Hi, I'm Parn!</h3>
          </div>
        </div>

        <h1 className="font-san font-bold text-3xl md:text-6xl mb-2 md:mb-8 w-4/5 text-center flex justify-center md:text-transparent md:bg-clip-text md:bg-gradient-to-r md:from-purple-400 md:to-red-600">
          I build&nbsp;digital products, <br />services, and teams
        </h1>
        <h2 className="font-semibold text-gray-400 md:text-2xl mb-4 md:mb-8 text-center">
          A digital product craftsman in London, UK
        </h2>
      </div>

      {/* <div className='container flex flex-nowrap flex-col items-center mb-20'>
        <h2 className='text-4xl font-bold mb-16'>👇🏼 Latest projects 👇🏼 </h2>
        <div className='flex flex-row overflow-x-scroll hide-scroll-bar'>
          {[1,2,3,4,5,6,7].map(i => (
            <div className='w-80 h-80 bg-white rounded-lg mr-4'>
              <div className='h-52 bg-purple-400 rounded-t-lg'></div>
              <h3 className='text-xl text-black p-4 font-bold'>sadf</h3>
            </div>
          ))}
        </div>
      </div>

      <div className='container flex flex-nowrap flex-col items-center mb-20'>
        <h2 className='text-4xl font-bold mb-16'>👇🏼 Posts 👇🏼 </h2>
        <div className='flex flex-row overflow-x-scroll hide-scroll-bar'>
          {[1,2,3,4,5,6,7].map(i => (
            <div className='w-80 h-80 bg-white rounded-lg mr-4'>
              <div className='h-52 bg-purple-400 rounded-t-lg'></div>
              <h3 className='text-xl text-black p-4 font-bold'>sadf</h3>
            </div>
          ))}
        </div>
      </div> */}

      <div className='fixed bottom-10 flex'>
        <a href="https://www.instagram.com/parnpresso/" target="_blank" rel="noreferrer" className="mr-4">
          <FaInstagram size={`2em`} color={'#8E949F'} />
        </a>
        <a href="https://www.linkedin.com/in/parn" target="_blank" rel="noreferrer">
          <FaLinkedin size={`2em`} color={'#8E949F'} />
        </a>
      </div>
    </div>
  );
}
export default HomePage;
