import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";

import HomePage from "./pages/Home";
import WorksPage from './pages/Works';
import HealthTagPage from './pages/Works/HealthTag';
import MajorProjectPage from './pages/Works/MajorProject';

function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/">
        <Route path="works">
          <Route path="health-tag" element={<HealthTagPage />} />
          <Route path="major-project" element={<MajorProjectPage />} />
          <Route index element={<WorksPage />} />
        </Route>
        <Route index element={<HomePage />} />
      </Route>
    </Routes>
  </BrowserRouter>
  );
}

export default App;
